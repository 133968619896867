import React from 'react';

import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import Comment from './Comment';
import styles from './css/ActionSheet.module.css';

interface Item {
    itemTitle: string;
    route: string;
}

interface SheetData {
    title: string;
    items: Item[];
    component?: string;
}

interface ActionSheetProps {
    sheetData: SheetData[];
    showActionSheet: boolean;
    onClose: () => void;
    onAlertTrigger?: () => void;
}

const ActionSheet: React.FC<ActionSheetProps> = ({ sheetData, showActionSheet, onClose, onAlertTrigger }) => {
    const navigate = useNavigate();

    const comments = [
        "서류를 접힌 부분 없이 깔끔하게 펴서 촬영해 주세요.",
        "촬영 시 카메라 초점을 흐려지지 않게 맞춰 주세요.",
        "그림자가 지지 않도록 배치하여 촬영해 주세요."
    ];

    const isReactNative = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);

    const sendMessage = (message: any) => {
        try {
            if (!message || typeof message !== 'object') {
                console.error('전송할 메시지가 유효하지 않습니다:', message);
                return;
            }

            const messageString = JSON.stringify(message);
            console.log('전송할 메시지:', messageString);

            const userAgent = navigator.userAgent;
            const isChromeOnIOS = /CriOS/i.test(userAgent);

            if (isIOS) {
                if (isChromeOnIOS) {
                    const event = new CustomEvent('message', { detail: messageString });
                    document.dispatchEvent(event);
                    console.log('iOS Chrome: document.dispatchEvent 사용');
                } else {
                    window.postMessage(messageString, '*');
                    console.log('iOS Safari: window.postMessage 사용');
                }
            } else {
                const event = new CustomEvent('message', { detail: messageString });
                document.dispatchEvent(event);
                console.log('Android/기타: document.dispatchEvent 사용');
            }
        } catch (error) {
            console.error('메시지 전송 중 오류 발생:', error);
        }
    };

    const openCamera = async () => {
        if (isReactNative) {
            window.ReactNativeWebView?.postMessage('openCamera');
        } else {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.capture = 'environment';

            input.onchange = async (e) => {
                const file = (e.target as HTMLInputElement).files?.[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64Image = reader.result as string;

                        sendMessage({
                            name: file.name,
                            type: file.type,
                            uri: URL.createObjectURL(file),
                            base64Uri: base64Image,
                            platform: isIOS ? 'iOS' : isAndroid ? 'Android' : 'Web',
                        });
                    };

                    reader.readAsDataURL(file);
                } else {
                    console.error('파일이 선택되지 않았습니다.');
                }
            };

            input.click();
        }
    };

    const openGallery = () => {
        if (isReactNative) {
            window.ReactNativeWebView?.postMessage('openGallery');
        } else {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';

            input.onchange = async (e) => {
                const file = (e.target as HTMLInputElement).files?.[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64Image = reader.result as string;

                        sendMessage({
                            name: file.name,
                            type: file.type,
                            uri: URL.createObjectURL(file),
                            base64Uri: base64Image,
                            platform: isIOS ? 'iOS' : isAndroid ? 'Android' : 'Web',
                        });
                    };
                    reader.readAsDataURL(file);
                }
            };

            input.click();
        }
    };

    const openFile = async () => {
        if (isReactNative) {
            window.ReactNativeWebView?.postMessage('openFile');
        } else {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '*/*';

            input.onchange = async (e) => {
                const file = (e.target as HTMLInputElement).files?.[0];
                if (file) {
                    const convertImageToBase64 = (file: File): Promise<string | null> => {
                        return new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                resolve(reader.result as string);
                            };
                            reader.onerror = () => {
                                reject('Base64 인코딩 실패');
                            };
                            reader.readAsDataURL(file);
                        });
                    };

                    let base64Uri: string | null = null;

                    if (file.type.startsWith('image/')) {
                        try {
                            base64Uri = await convertImageToBase64(file);
                        } catch (error) {
                            console.error('이미지 Base64 변환 실패:', error);
                        }
                    }

                    sendMessage({
                        name: file.name,
                        type: file.type,
                        uri: URL.createObjectURL(file),
                        base64Uri,
                        platform: isIOS ? 'iOS' : isAndroid ? 'Android' : 'Web',
                    });
                }
            };

            input.click();
        }
    };

    const handleItemClick = (route: string) => {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
            navigate(route);
            onClose();
        } else {
            if (onAlertTrigger) {
                onAlertTrigger();
            }
            onClose();
        }
    };

    return (
        <>
            {showActionSheet && (
                <>
                    <motion.div
                        className={styles.actionSheet}
                        initial={{ y: 600 }}
                        animate={{ y: 0 }}
                        exit={{ y: 600 }}
                        transition={{ duration: 0.5 }}
                    >
                        {!sheetData[0].component && (
                            <>
                                <div className={styles.header}>
                                    <i className="icon icon-20"></i>
                                    <h5>{sheetData[0].title}</h5>
                                    <i className="icon icon-x icon-20" onClick={onClose}></i>
                                </div>
                                <div className={styles.items}>
                                    {sheetData[0].items.map((item, index) => (
                                        <>
                                            <div key={index}>
                                                <p className="fw600">{item.itemTitle}</p>
                                                <button
                                                    className={styles.button}
                                                    onClick={() => handleItemClick(item.route)}
                                                >
                                                    보기
                                                </button>
                                            </div>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: 1,
                                                    background: 'var(--disabled)',
                                                }}
                                                className={styles.underline}
                                            ></div>
                                        </>
                                    ))}
                                </div>
                            </>
                        )}
                        {sheetData[0].component === '증빙서류 첨부' && (
                            <>
                                <div className={styles.header}>
                                    <i className="icon icon-20"></i>
                                    <h5>증빙서류 첨부</h5>
                                    <i className="icon icon-x icon-20" onClick={onClose}></i>
                                </div>
                                <div className={styles.items}>
                                    <Comment comments={comments} />
                                    {isReactNative && <div className="mt24">
                                        <div onClick={openCamera}>
                                            <i className="icon icon-camera icon-24 mr6"></i>
                                            <p className="fw600">카메라</p>
                                        </div>
                                        <i className="icon icon-arrow-right icon-12"></i>
                                    </div>}
                                    <div className={styles.underline}></div>
                                    <div className={isReactNative ? 'mt24' : ''}>
                                        <div onClick={openGallery}>
                                            <i className="icon icon-photo icon-24 mr6"></i>
                                            <p className="fw600">앨범</p>
                                        </div>
                                        <i className="icon icon-arrow-right icon-12"></i>
                                    </div>
                                    <div className={styles.underline}></div>
                                    <div>
                                        <div onClick={openFile}>
                                            <i className="icon icon-clip icon-24 mr6"></i>
                                            <p className="fw600">내 파일</p>
                                        </div>
                                        <i className="icon icon-arrow-right icon-12"></i>
                                    </div>
                                    <div className={styles.underline}></div>
                                </div>
                            </>
                        )}
                    </motion.div>
                    <motion.div
                        className="background"
                        onClick={onClose}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    />
                </>
            )}
        </>
    );
};

export default ActionSheet;
